import React from 'react';
import { useTranslation } from 'react-i18next';

import alertingSystem from '../../images/resources/Screenshot16.png';
import endUsers from '../../images/resources/Screenshot17.png';
import VOD from '../../images/resources/Screenshot18.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>
                {t('siteMetadata.resources.articles.multiDevice.h2')}
            </h2>
            <figure>
                <img src="/articles/old/MVNO_QoE_Monitoring.jpg" id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.articles.multiDevice.mainText1')}</p>
            <p>{t('siteMetadata.resources.articles.multiDevice.mainText2')}</p>
            <figure>
                <img src={alertingSystem} alt='Alerting system' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.multiDevice.sectionATitle')}</h2>
            <p>{t('siteMetadata.resources.articles.multiDevice.sectionAContent1')}</p>
            <p>{t('siteMetadata.resources.articles.multiDevice.sectionAContent2')}</p>
            <figure>
                <img src={endUsers} alt='End users' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.multiDevice.sectionBTitle')}</h2>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.articles.multiDevice.sectionBContent1')}}></p>
            <figure>
                <img src={VOD} alt='VoD' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.multiDevice.sectionCTitle')}</h2>
            <p>{t('siteMetadata.resources.articles.multiDevice.sectionCContent1')}</p>
        </React.Fragment>
    );
}

export default Content;
