import React from 'react';
import { useTranslation } from 'react-i18next';

import QOE from '../../images/resources/Screenshot13.png';
import smartping from '../../images/resources/Screenshot14.png';
import earth from '../../images/resources/Screenshot15.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>
                {t(`siteMetadata.resources.articles.OTT.h2`)}
            </h2>
            <figure>
                <img src="/articles/old/OTT_Video_Services_Monitoring.jpg" id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t(`siteMetadata.resources.articles.OTT.mainText1`)}</p>
            <p>{t(`siteMetadata.resources.articles.OTT.mainText2`)}</p>
            <p>{t(`siteMetadata.resources.articles.OTT.mainText3`)}</p>
            <figure>
                <img src={QOE} alt='QoE' />
            </figure>
            <h2>{t(`siteMetadata.resources.articles.OTT.sectionATitle`)}</h2>
            <p>{t(`siteMetadata.resources.articles.OTT.sectionAContent1`)}</p>
            <p>{t(`siteMetadata.resources.articles.OTT.sectionAContent2`)}</p>
            <figure>
                <img src={smartping} alt='Smartping' />
            </figure>
            <h2>{t(`siteMetadata.resources.articles.OTT.sectionBTitle`)}</h2>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.articles.OTT.sectionBContent1`)}}></p>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.articles.OTT.sectionBContent2`)}}></p>
            <figure>
                <img src={earth} alt='All devices' />
            </figure>
            <h2>{t(`siteMetadata.resources.articles.OTT.sectionCTitle`)}</h2>
            <p>{t(`siteMetadata.resources.articles.OTT.sectionCContent1`)}</p>
            <p>{t(`siteMetadata.resources.articles.OTT.sectionCContent2`)}</p>
        </React.Fragment>
    );
}

export default Content;
