import React from 'react';
import { useTranslation } from 'react-i18next';

import devices from '../../images/resources/allDevices.png';
import measure from '../../images/resources/measure.png';
import viewQOE from '../../images/resources/viewQOE.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>
                {t(`siteMetadata.resources.articles.SmartTV.h2`)}
            </h2>
            <figure>
                <img src="/articles/old/SmartTVBanner.png" id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t(`siteMetadata.resources.articles.SmartTV.mainText1`)}</p>
            <figure>
                <img src={devices} alt='Devices' />
            </figure>
            <h2>{t(`siteMetadata.resources.articles.SmartTV.sectionATitle`)}</h2>
            <p>{t(`siteMetadata.resources.articles.SmartTV.sectionAContent1`)}</p>
            <figure>
                <img src={measure} alt='Measure' />
            </figure>
            <h2>{t(`siteMetadata.resources.articles.SmartTV.sectionBTitle`)}</h2>
            <p>{t(`siteMetadata.resources.articles.SmartTV.sectionBContent1`)}</p>
            <figure>
                <img src={viewQOE} alt='View QoE' />
            </figure>
            <h2>{t(`siteMetadata.resources.articles.SmartTV.sectionCTitle`)}</h2>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.articles.SmartTV.sectionCContent1`)}}></p>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.articles.SmartTV.sectionDContent1`)}}></p>
        </React.Fragment>
    );
}

export default Content;
