import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight';

import banner from '../../images/resources/banners/londonbanner.jpg';
import london1 from '../../images/resources/london1.jpg';
import london2 from '../../images/resources/london2.png';
import london3 from '../../images/resources/london3.png';
import london4 from '../../images/resources/london4.png';
import london5 from '../../images/resources/london5.png';
import london6 from '../../images/resources/london6.png';
import london7 from '../../images/resources/london7.png';
import london8 from '../../images/resources/london8.png';
import london9 from '../../images/resources/london9.png';
import london10 from '../../images/resources/london10.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>{t('siteMetadata.resources.snapshots.witbeLondon.introTitle')}</h2>
            <figure>
                <img src={banner} id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.introContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.introContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.introContent3')}</p>
            <figure>
                <img src={london1} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.introContent4')}</p>
            <ul>
                <li>{t('siteMetadata.resources.snapshots.witbeLondon.ul1.ulItem1')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeLondon.ul1.ulItem2')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeLondon.ul1.ulItem3')}</li>
                <li>{t('siteMetadata.resources.snapshots.witbeLondon.ul1.ulItem4')}</li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.introContent5')}</p>
            <ul>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeLondon.ul2.ulItem1')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeLondon.ul2.ulItem2')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeLondon.ul2.ulItem3')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeLondon.ul2.ulItem4')}}></li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.introContent6')}</p>
            <h2>{t('siteMetadata.resources.snapshots.witbeLondon.overviewTitle')}</h2>
            <figure>
                <img src={london2} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.overviewContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.overviewContent2')}</p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeLondon.overviewContent3')}}></p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeLondon.overviewContent4')}}></p>
            <h2>{t('siteMetadata.resources.snapshots.witbeLondon.peakTitle')}</h2>
            <figure>
                <img src={london3} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.peakContent1')}</p>
            <figure>
                <img src={london4} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.peakContent2')}</p>
            <figure>
                <img src={london5} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.peakContent3')}</p>
            <div className={newSnapshots.quotedText}>
                <span></span>
                <p className={newSnapshots.quotedTextWithPargin}>
                    <FontAwesomeIcon icon={faQuoteLeft} className={newSnapshots.quoteIconLeft} />
                    {t('siteMetadata.resources.snapshots.witbeLondon.peakQuote')}
                    <FontAwesomeIcon icon={faQuoteRight} className={newSnapshots.quoteIconRight} />
                </p>
            </div>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.peakContent4')}</p>
            <figure>
                <img src={london6} />
                <figcaption>{t(`siteMetadata.resources.snapshots.witbeLondon.peakVideoSubtitle1`)}</figcaption>
            </figure>
            <figure>
                <img src={london7} />
                <figcaption>{t(`siteMetadata.resources.snapshots.witbeLondon.peakVideoSubtitle2`)}</figcaption>
            </figure>
            <h2>{t('siteMetadata.resources.snapshots.witbeLondon.influenceTitle')}</h2>
            <figure>
                <img src={london8} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeLondon.influenceContent1')}}></p>
            <figure>
                <img src={london9} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeLondon.influenceContent2')}}></p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeLondon.influenceContent3')}}></p>
            <h2>{t('siteMetadata.resources.snapshots.witbeLondon.blindTitle')}</h2>
            <figure>
                <img src={london10} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.witbeLondon.blindContent1')}}></p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.blindContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.blindContent3')}</p>
            <h2>{t('siteMetadata.resources.snapshots.witbeLondon.conclusionTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.conclusionContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.conclusionContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.conclusionContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.conclusionContent4')}</p>
            <h2>{t('siteMetadata.resources.snapshots.witbeLondon.QOESnapshotsTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.QOESnapshotsContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.QOESnapshotsContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.QOESnapshotsContent3')}</p>
        </React.Fragment>
    );
}

export default Content;
