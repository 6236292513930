
import React from 'react';
import { useTranslation } from 'react-i18next';

import tv from '../../images/resources/monitor_your_services.jpg';
import activeMonitoring from '../../images/resources/ensure_flawless_interactives.jpg';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>
                {t('siteMetadata.resources.articles.tvVod.h2')}
            </h2>
            <figure>
                <img src="/articles/old/header.jpg" id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.articles.tvVod.mainText1')}</p>
            <p>{t('siteMetadata.resources.articles.tvVod.mainText2')}</p>
            <figure>
                <img src={tv} alt='TV' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.tvVod.sectionATitle')}</h2>
            <p>{t('siteMetadata.resources.articles.tvVod.sectionAContent1')}</p>
            <p>{t('siteMetadata.resources.articles.tvVod.sectionAContent2')}</p>
            <figure>
                <img src={activeMonitoring} alt='Active monitoring' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.tvVod.sectionBTitle')}</h2>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.articles.tvVod.sectionBContent1')}}></p>
        </React.Fragment>
    );
}

export default Content;
