import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight';

import banner from '../../images/resources/banners/applebanner.jpeg';
import apple1 from '../../images/resources/apple1.png';
import apple2 from '../../images/resources/apple2.png';
import apple3 from '../../images/resources/apple3.png';
import apple4 from '../../images/resources/apple4.png';
import apple5 from '../../images/resources/apple5.png';
import apple6 from '../../images/resources/apple6.png';
import apple7 from '../../images/resources/apple7.png';
import apple8 from '../../images/resources/apple8.png';
import apple9 from '../../images/resources/apple9.png';
import apple10 from '../../images/resources/apple10.png';
import huluTrace from '../../images/resources/Hulu-trace.mp4';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUS.introTitle')}</h2>
            <figure>
                <img src={banner} id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.introContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.introContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.introContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.introContent4')}</p>
            <ul>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.ul1.ulItem1')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.ul1.ulItem2')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.ul1.ulItem3')}}></li>
            </ul>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.introContent5')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.introContent6')}</p>
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUS.overviewTitle')}</h2>
            <figure>
                <img src={apple1} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.overviewContent1')}</p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.overviewContent2')}}></p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.overviewContent3')}}></p>
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUS.initialBufferingTitle')}</h2>
            <figure>
                <img src={apple2} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.initialBufferingContent1')}}></p>
            <figure>
                <img src={apple3} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.initialBufferingContent2')}}></p>
            <figure>
                <img src={apple4} alt='Screenshot' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.initialBufferingContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.initialBufferingContent4')}</p>
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUS.videoAvailabilityTitle')}</h2>
            <figure>
                <img src={apple5} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.videoAvailabilityContent1')}}></p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.videoAvailabilityContent2')}}></p>
            <div className={newSnapshots.resourceSectionVideo}>
                <video controls className={newSnapshots.snapshotsVideoContainer}>
                    <source src={huluTrace} type='video/mp4' />
                    {t('siteMetadata.resources.snapshots.stadia.videoErrorMessage')}
                </video>
            </div>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.videoAvailabilityContent3')}</p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.videoAvailabilityContent4')}}></p>
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUS.blurScoresTitle')}</h2>
            <figure>
                <img src={apple6} alt='Screenshot' />
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.bestOTTAppUS.blurScoresContent1')}}></p>
            <div className={newSnapshots.quotedText}>
                <span></span>
                <p className={newSnapshots.quotedTextWithPargin}>
                    <FontAwesomeIcon icon={faQuoteLeft} className={newSnapshots.quoteIconLeft} />
                    {t('siteMetadata.resources.snapshots.bestOTTAppUS.blurScoresQuote')}
                    <FontAwesomeIcon icon={faQuoteRight} className={newSnapshots.quoteIconRight} />
                </p>
            </div>
            <figure>
                <img src={apple7} alt='Screenshot' />
                <figcaption>{t(`siteMetadata.resources.snapshots.bestOTTAppUS.blurScoresSubtitle1`)}</figcaption>
            </figure>
            <figure>
                <img src={apple8} alt='Screenshot' />
                <figcaption>{t(`siteMetadata.resources.snapshots.bestOTTAppUS.blurScoresSubtitle2`)}</figcaption>
            </figure>
            <figure>
                <img src={apple9} alt='Screenshot' />
                <figcaption>{t(`siteMetadata.resources.snapshots.bestOTTAppUS.blurScoresSubtitle3`)}</figcaption>
            </figure>
            <figure>
                <img src={apple10} alt='Screenshot' />
                <figcaption>{t(`siteMetadata.resources.snapshots.bestOTTAppUS.blurScoresSubtitle4`)}</figcaption>
            </figure>
            <h2>{t('siteMetadata.resources.snapshots.bestOTTAppUS.conclusionTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.conclusionContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.conclusionContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.conclusionContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.bestOTTAppUS.conclusionContent4')}</p>
            <h2>{t('siteMetadata.resources.snapshots.witbeLondon.QOESnapshotsTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.QOESnapshotsContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.QOESnapshotsContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.witbeLondon.QOESnapshotsContent3')}</p>
        </React.Fragment>
    );
}

export default Content;
