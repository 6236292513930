import React from 'react';
import { useTranslation } from 'react-i18next';

import unifiedTests from '../../images/resources/Screenshot25.png';
import perceptionTesting from '../../images/resources/Screenshot26.png';
import sideEffects from '../../images/resources/Screenshot18.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>
                {t('siteMetadata.resources.articles.broadbandTriplePlay.h2')}
            </h2>
            <figure>
                <img src="/articles/old/Broadband_Voice_and_TV_Triple_Play_Monitoring.jpg" id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.articles.broadbandTriplePlay.mainText1')}</p>
            <p>{t('siteMetadata.resources.articles.broadbandTriplePlay.mainText2')}</p>
            <p>{t('siteMetadata.resources.articles.broadbandTriplePlay.mainText3')}</p>
            <figure>
                <img src={unifiedTests} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.broadbandTriplePlay.sectionATitle')}</h2>
            <p>{t('siteMetadata.resources.articles.broadbandTriplePlay.sectionAContent1')}</p>
            <figure>
                <img src={perceptionTesting} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.broadbandTriplePlay.sectionBTitle')}</h2>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.articles.broadbandTriplePlay.sectionBContent1')}}></p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.articles.broadbandTriplePlay.sectionBContent2')}}></p>
            <figure>
                <img src={sideEffects} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.broadbandTriplePlay.sectionCTitle')}</h2>
            <p>{t('siteMetadata.resources.articles.broadbandTriplePlay.sectionCContent1')}</p>
        </React.Fragment>
    );
}

export default Content;
