import React from 'react';
import { useTranslation } from 'react-i18next';

import oneScreen from '../../images/resources/REC_One_Screen_New.png';
import professionals from '../../images/resources/REC_Made_For_Professionals.png';
import takeControl from '../../images/resources/REC_Take_Control.png';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>
                {t(`siteMetadata.resources.articles.RECUseCases.h2`)}
            </h2>
            <figure>
                <img src="/articles/old/REC_New_Banner.png" id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t(`siteMetadata.resources.articles.RECUseCases.mainText1`)}</p>
            <figure>
                <img src={takeControl} alt='Take control' />
            </figure>
            <h2>{t(`siteMetadata.resources.articles.RECUseCases.sectionATitle`)}</h2>
            <p>{t(`siteMetadata.resources.articles.RECUseCases.sectionAContent1`)}</p>
            <figure>
                <img src={oneScreen} alt='One screen' />
            </figure>
            <h2>{t(`siteMetadata.resources.articles.RECUseCases.sectionBTitle`)}</h2>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.articles.RECUseCases.sectionBContent1`)}}></p>
            <figure>
                <img src={professionals} alt='Professionals' />
            </figure>
            <h2>{t(`siteMetadata.resources.articles.RECUseCases.sectionCTitle`)}</h2>
            <p>{t(`siteMetadata.resources.articles.RECUseCases.sectionCContent1`)}</p>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.articles.RECUseCases.sectionDContent1`)}}></p>
        </React.Fragment>
    );
}

export default Content;
