import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight';

import banner from '../../images/resources/Snapshot7Picture1.jpg';
import snapshot7Picture2 from '../../images/resources/Snapshot7Picture2.png';
import snapshot7Picture3 from '../../images/resources/Snapshot7Picture3.png';
import snapshot7Picture4 from '../../images/resources/Snapshot7Picture4.png';
import snapshot7Picture6 from '../../images/resources/Snapshot7Picture6.png';
import snapshot7Picture5 from '../../images/resources/Snapshot7Picture5.png';
import Snapshot7Video1 from '../../images/resources/Crash during Launch - 4k.mp4';
import Snapshot7Video2 from '../../images/resources/Crash during Intro - 4k.mp4';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <p>{t('siteMetadata.resources.snapshots.stadia.about1')}</p>
            <h2>{t('siteMetadata.resources.snapshots.stadia.introTitle')}</h2>
            <figure>
                <img src={banner} id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.snapshots.stadia.introContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.introContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.introContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.introContent4')}</p>
            <ul>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.ul1.ulItem1')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.ul1.ulItem2')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.ul1.ulItem3')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.ul1.ulItem4')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.ul1.ulItem5')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.ul1.ulItem6')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.ul1.ulItem7')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.ul1.ulItem8')}}></li>
            </ul>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.introContent5')}}></p>
            <p>{t('siteMetadata.resources.snapshots.stadia.introContent6')}</p>
            <h2>{t('siteMetadata.resources.snapshots.stadia.overviewTitle')}</h2>
            <figure>
                <img src={snapshot7Picture2} alt='Screenshot'>
                </img>
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.overviewContent1')}}></p>
            <figure>
                <img src={snapshot7Picture3} alt='Screenshot'>
                </img>
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.overviewContent2')}}></p>
            <p>{t('siteMetadata.resources.snapshots.stadia.overviewContent3')}</p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.overviewContent4')}}></p>
            <div className={newSnapshots.quotedText}>
                <span></span>
                <p className={newSnapshots.quotedTextWithPargin}>
                    <FontAwesomeIcon icon={faQuoteLeft} className={newSnapshots.quoteIconLeft} />
                    {t('siteMetadata.resources.snapshots.stadia.overviewQuote')}
                    <FontAwesomeIcon icon={faQuoteRight} className={newSnapshots.quoteIconRight} />
                </p>
            </div>
            <figure>
                <img src={snapshot7Picture4} alt='Screenshot'>
                </img>
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.overviewContent5')}}></p>
            <h2>{t('siteMetadata.resources.snapshots.stadia.crashesTitle')}</h2>
            <div className={newSnapshots.resourceSectionVideo}>
                <video controls className={newSnapshots.snapshotsVideoContainer}>
                    <source src={Snapshot7Video1} type='video/mp4' />
                    {t('siteMetadata.resources.snapshots.stadia.videoErrorMessage')}
                </video>
            </div>
            <p>{t('siteMetadata.resources.snapshots.stadia.crashesContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.crashesContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.crashesContent3')}</p>
            <div className={newSnapshots.resourceSectionVideo}>
                <video controls className={newSnapshots.snapshotsVideoContainer}>
                    <source src={Snapshot7Video2} type='video/mp4' />
                    {t('siteMetadata.resources.snapshots.stadia.videoErrorMessage')}
                </video>
            </div>
            <p>{t('siteMetadata.resources.snapshots.stadia.crashesContent4')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.crashesContent5')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.crashesContent6')}</p>
            <h2>{t('siteMetadata.resources.snapshots.stadia.degradationTitle')}</h2>
            <figure>
                <img src={snapshot7Picture6} />
                <figcaption>{t(`siteMetadata.resources.snapshots.stadia.degradationVideoCaption1`)}</figcaption>
            </figure>
            <figure>
                <img src={snapshot7Picture5} />
                <figcaption>{t(`siteMetadata.resources.snapshots.stadia.degradationVideoCaption2`)}</figcaption>
            </figure>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.snapshots.stadia.degradationContent1')}}></p>
            <div className={newSnapshots.quotedText}>
                <span></span>
                <p className={newSnapshots.quotedTextWithPargin}>
                    <FontAwesomeIcon icon={faQuoteLeft} className={newSnapshots.quoteIconLeft} />
                    {t('siteMetadata.resources.snapshots.stadia.degradationQuote')}
                    <FontAwesomeIcon icon={faQuoteRight} className={newSnapshots.quoteIconRight} />
                </p>
            </div>
            <p>{t('siteMetadata.resources.snapshots.stadia.degradationContent2')}</p>
            <h2>{t('siteMetadata.resources.snapshots.stadia.conclusionTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.stadia.conclusionContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.conclusionContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.conclusionContent3')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.conclusionContent4')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.conclusionContent5')}</p>
            <h2>{t('siteMetadata.resources.snapshots.stadia.QOESnapshotsTitle')}</h2>
            <p>{t('siteMetadata.resources.snapshots.stadia.QOESnapshotsContent1')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.QOESnapshotsContent2')}</p>
            <p>{t('siteMetadata.resources.snapshots.stadia.QOESnapshotsContent3')}</p>
        </React.Fragment>
    );
}

export default Content;
