import React from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons/faQuoteRight';

import banner from '../../images/resources/Snapshot3Picture5.jpeg';
import Snapshot3Picture3 from '../../images/resources/Snapshot3Picture3.jpeg';
import Snapshot3Picture4 from '../../images/resources/Snapshot3Picture4.jpeg';
import SnapshotVideo from '../../images/resources/netflix_snap3.mp4';
import globalOverview from '../../images/resources/Screenshot43.png';
import peakHours from '../../images/resources/Screenshot44.png';
import videoTron from '../../images/resources/Screenshot45.png';
import crave from '../../images/resources/Snapshot3Picture1.jpeg';
import youtubeVsNetflix from '../../images/resources/Snapshot3Picture2.jpeg';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>{t(`siteMetadata.resources.snapshots.witbeCanada.introTitle`)}</h2>
            <figure>
                <img src={banner} id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.introContent1`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.introContent2`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.introContent3`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.introContent4`)}</p>
            <ul>
                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.witbeCanada.ul1.ulItem1`)}}></li>
                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.witbeCanada.ul1.ulItem2`)}}></li>
                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.witbeCanada.ul1.ulItem3`)}}></li>
                <li dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.witbeCanada.ul1.ulItem4`)}}></li>
            </ul>
            <h2>{t(`siteMetadata.resources.snapshots.witbeCanada.overviewTitle`)}</h2>
            <figure>
                <img src={globalOverview} alt='Screenshot'>
                </img>
            </figure>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.overviewContent1`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.overviewContent2`)}</p>
            <p dangerouslySetInnerHTML={{__html: t(`siteMetadata.resources.snapshots.witbeCanada.overviewContent3`)}}></p>
            <h2>{t(`siteMetadata.resources.snapshots.witbeCanada.mobileOperatorsTitle`)}</h2>
            <figure>
                <img src={peakHours} alt='Screenshot'>
                </img>
            </figure>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.mobileOperatorsContent1`)}</p>
            <figure>
                <img src={videoTron} alt='Screenshot'>
                </img>
            </figure>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.mobileOperatorsContent2`)}</p>
            <div className={newSnapshots.resourceSectionVideo}>
                <video controls className={newSnapshots.snapshotsVideoContainer}>
                    <source src={SnapshotVideo} type="video/mp4" />
                    {t(`siteMetadata.resources.snapshots.witbeCanada.videoErrorMessage`)}
                </video>
            </div>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.mobileOperatorsContent3`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.mobileOperatorsContent4`)}</p>
            <h2>{t(`siteMetadata.resources.snapshots.witbeCanada.videoAvailabilityTitle`)}</h2>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.videoAvailabilityContent1`)}</p>
            <figure>
                <img src={crave} alt='Screenshot'>
                </img>
            </figure>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.videoAvailabilityContent2`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.videoAvailabilityContent3`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.videoAvailabilityContent4`)}</p>
            <h2>{t(`siteMetadata.resources.snapshots.witbeCanada.heavyweightsTitle`)}</h2>
            <figure>
                <img src={youtubeVsNetflix} alt='Screenshot'>
                </img>
            </figure>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.heavyweightsContent1`)}</p>
            <div className={newSnapshots.quotedText}>
                <span></span>
                <p className={newSnapshots.quotedTextWithPargin}>
                    <FontAwesomeIcon icon={faQuoteLeft} className={newSnapshots.quoteIconLeft} />
                        {t(`siteMetadata.resources.snapshots.witbeCanada.heavyweightsQuote`)}
                    <FontAwesomeIcon icon={faQuoteRight} className={newSnapshots.quoteIconRight} />
                </p>
            </div>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.heavyweightsContent2`)}</p>
            <figure>
                <img src={Snapshot3Picture3} />
                <figcaption>{t(`siteMetadata.resources.snapshots.witbeCanada.heavyweightsVideoCaption1`)}</figcaption>
            </figure>
            <figure>
                <img src={Snapshot3Picture4} />
                <figcaption>{t(`siteMetadata.resources.snapshots.witbeCanada.heavyweightsVideoCaption2`)}</figcaption>
            </figure>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.heavyweightsContent3`)}</p>
            <h2>{t(`siteMetadata.resources.snapshots.witbeCanada.conclusionTitle`)}</h2>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.conclusionContent1`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.conclusionContent2`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.conclusionContent3`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.conclusionContent4`)}</p>
            <h2>{t(`siteMetadata.resources.snapshots.witbeCanada.QOESnapshotsTitle`)}</h2>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.QOESnapshotsContent1`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.QOESnapshotsContent2`)}</p>
            <p>{t(`siteMetadata.resources.snapshots.witbeCanada.QOESnapshotsContent3`)}</p>
        </React.Fragment>
    );
}

export default Content;
