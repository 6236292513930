import React from 'react';
import { useTranslation } from 'react-i18next';

import devices from '../../images/resources/Cloud_Gaming_Over_5GLatency_On_Screen_Stadia.jpg';
import measure from '../../images/resources/Cloud_Gaming_Over_5GVideo_Quality.jpg';
import viewQoE from '../../images/resources/Cloud_Gaming_Over_5GBenchmarking.jpg';

import newSnapshots from '../../pages/styles/newSnapshots.module.scss';

const Content = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h2>
                {t('siteMetadata.resources.articles.5GUseCase.h2')}
            </h2>
            <figure>
                <img src="/articles/old/Cloud_Gaming_Over_5GHero.png" id={newSnapshots.mainImg} alt='Banner' />
            </figure>
            <p>{t('siteMetadata.resources.articles.5GUseCase.mainText1')}</p>
            <figure>
                <img src={devices} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.5GUseCase.sectionATitle')}</h2>
            <p>{t('siteMetadata.resources.articles.5GUseCase.sectionAContent1')}</p>
            <figure>
                <img src={measure} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.5GUseCase.sectionBTitle')}</h2>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.articles.5GUseCase.sectionBContent1')}}></p>
            <figure>
                <img src={viewQoE} alt='All devices' />
            </figure>
            <h2>{t('siteMetadata.resources.articles.5GUseCase.sectionCTitle')}</h2>
            <p>{t('siteMetadata.resources.articles.5GUseCase.sectionCContent1')}</p>
            <p dangerouslySetInnerHTML={{__html: t('siteMetadata.resources.articles.5GUseCase.sectionDContent1')}}></p>
        </React.Fragment>
    );
}

export default Content;
